<template>
    <div>
        <h2 class="modal-section-title">contact details</h2>
        <div class="form-row">
            <div class="form-col">
                <label>full name</label>
                <span class="form-data">{{ contact.website_leads_name }}</span>
            </div>
            <div class="form-col">
                <label>email address</label>
                <span class="form-data">{{ contact.email }}</span>
            </div>
            <div class="form-col">
                <label>phone number</label>
                <span class="form-data">{{ formatPhone(contact.phone) }}</span>
            </div>
        </div>
        <div class="form-row mt-8">
            <div class="form-col">
                <label>message</label>
                <span class="form-data">{{ contact.extras ? JSON.parse(contact.extras).message : '' }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import ModalNavigation from "@/mixins/ModalNavigation";
import {formatPhone} from "@/utils/String";

export default {
    mixins: [ ModalNavigation ],
    data: () => {
        return {
            contact: {},
        }
    },
    computed: {
        ...mapGetters({
            getContactById: 'contacts/getContactById'
        }),
    },
    methods: {
        formatPhone,
    },
    mounted() {
        this.contact = this.getContactById(this.$route.params.contactId)
    }
}
</script>


<style scoped>
label {
    @apply font-frank font-bold text-sm tracking-wider text-left;
}
</style>